import React, { useEffect } from "react";
import servisorIcon from "../../../assets/servisorWhiteLogo.png";
import plusIcon from "../../../assets/forms/plus.svg";
import vehicleTag from "../../../assets/tags/locationnew.svg";
import dataAnimation from "../../../assets/lottie/Servisor_04_Service Location.json";
import NextIcon from "../../../assets/icons/ChevronRightFilled.svg";

import {
  Button,
  Box,
  Container,
  Typography,
  Select,
  FormControl,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  BottomNavigation,
  Paper,
} from "@mui/material";

import { useState } from "react";
import "../Onboarding.scss";
import { useHistory } from "react-router-dom";

import { Geocode, initService } from "../../../services/apis/location.service";

import { LocationModel } from "../../../models/LocationModel";
import { useGlobalContext } from "../../../services/context/globalContext";
import { HeaderText } from "../../../components/common/HeaderText";
import { SubHeaderText } from "../../../components/common/SubHeaderText";
import ProgressBar from "../../../components/common/ProgressBar";
import { PageLayout } from "../../../components/layout/PageLayout";
import IssueHelpButton from "../../../components/common/IssueHelpButton";

export const LocationEvaluation: React.FC<any> = ({
  clicked,
  setClicked,
  step,
  backStep,
  forwardStep,
  progressValue,
}) => {
  // @ts-ignore
  const { setAreaCodeState } = useGlobalContext();

  const history = useHistory();
  const [area, setArea]: any = useState([]);
  const [selectedArea, setSelectedArea] = useState();
  const [locationsList, setLocationsList] = useState([]);
  const [location, setLocation] = useState(new LocationModel({}));
  const [searchTerm, setSearchTerm]: any = useState(null);
  const [showSearch, setSearch] = useState(false);
  const [areaCode, setAreaCode]: any = useState("");

  const customLocation = {
    header: "Select Location",
    subHeader: "Select a location.",
  };

  /**
   * Saves the selected location, formats the area code, and navigates to the confirmation page.
   */

  const [locationSelected, setLocationSelected] = useState(false);

  const saveLocation = () => {
    // !B - Relevant area code stored in local storage
    const formatedAreaCode = areaCode;
    setAreaCodeState(JSON.stringify(parseFloat(formatedAreaCode)));
    localStorage.setItem("areaCode", formatedAreaCode);
    // !Temporarily hard coding the rate and area for use in the calculations
    localStorage.setItem("temporary-rate-data", JSON.stringify(selectedArea));

    localStorage.setItem("location", JSON.stringify(location));

    history.push("/confirmation");
  };

  /**
   * Assigns a location based on the provided event description, retrieves its details using Geocode,
   * and updates the location state.
   *
   * @param {Object} event - The event object containing the location description.
   */
  const assignLocation = (event: any) => {
    Geocode(event.description).then((response: any) => {
      location.location = [
        response.geometry.location.lat(),
        response.geometry.location.lng(),
      ];
      location.description = response.formatted_address;
      location.place_id = response.place_id;
      setSearchTerm(location.description);
      setLocation(new LocationModel(location));
      setSearch(false);
      setLocationSelected(true);
    });
  };

  /**
   * Performs an effect to retrieve service areas and set them in the component state.
   * This effect runs once after the component is mounted.
   */
  useEffect(() => {
    // ! Will implement area call once it is availble for now we hard code values.
    // getArea().then((data) => {
    //     const serviceAreas = Object.entries(data[0].province[0]).map(
    //         ([province, areaCode]) => {
    //             const myObj = {
    //                 area: province,
    //                 code: areaCode,
    //             };
    //             return myObj;
    //         }
    //     );
    //     setArea(serviceAreas);
    // });

    // Exclusive of VAT
    setArea([
      { area: "Fourways", code: "0001", rate: 900 },
      { area: "Bethal", code: "0002", rate: 867.5 },
      { area: "Emalahleni", code: "0003", rate: 800 },
      { area: "Middelburg", code: "0004", rate: 800 },
      { area: "Potchesfstroom", code: "0005", rate: 800 },
      { area: "Randburg", code: "0006", rate: 900 },
      { area: "Paulshof", code: "0007", rate: 900 },
      { area: "Secunda", code: "0008", rate: 867.5 },
      { area: "Bryanston", code: "0009", rate: 900 },
      { area: "Honeydew", code: "0010", rate: 900 },
      { area: "Sunninghill", code: "0011", rate: 900 },
    ]);
  }, []);

  // Inclusive of VAT
  //vat = 0.15
  //rateInc = rate + (rate * vat)
  // setArea([
  //     { area: 'Fourways', code: '0001', rate: 920 },
  //     { area: 'Bethal', code: '0002', rate: 943 },
  //     { area: 'Emalahleni', code: '0003', rate: 917.70 },
  //     { area: 'Middelburg', code: '0004', rate: 917.70 },
  //     { area: 'Potchesfstroom', code: '0005', rate: 948.75 },
  // ]);
  // }, []);

  return (
    <PageLayout animationData={dataAnimation}>
      <HeaderText headerText="SERVICE LOCATION" headerImage={servisorIcon} />
      <Box
        sx={{
          backgroundColor: "#FFF",
          overflow: "auto",
          maxHeight: "calc(100vh - 64px)",
          paddingBottom: "100px",
          position: "relative",
          paddingTop: { xs: "50px", lg: "100px" },
          display: "flex",
          alignSelf: { xs: "normal", lg: "center" },
          flexDirection: { xs: "column", lg: "column" },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignSelf: "center",
            width: { xs: "100%", lg: "70%" },
          }}
        >
          <img src={vehicleTag} alt="/" />
        </Box>

        <SubHeaderText headerText="Which area would you like the service to take place?" />

        <Container
          disableGutters
          sx={{
            width: { xs: "90%", lg: "70%" },

            marginTop: { xs: "-10px", lg: "0" },
            borderRadius: { xs: "4px", lg: "4px" },
            backgroundColor: "#FFF",
            paddingX: { xs: "20px", lg: "0" },
          }}
        >
          <Box>
            <Box
              sx={{
                display: { xs: "flex", lg: "none" },
                alignSelf: "left",
                width: { xs: "100%", lg: "70%" },
                paddingTop: { xs: "20px", lg: "0" },
              }}
            >
              <img src={vehicleTag} alt="/" />
            </Box>
            <form>
              <Box sx={{ py: "20px" }}>
                <FormControl fullWidth>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--gray-700, #344054)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Location{" "}
                    </Typography>

                    <Select
                      displayEmpty
                      IconComponent={(props) => (
                        <img src={plusIcon} alt="/" {...props} />
                      )}
                      sx={{
                        textAlign: "start",
                        borderRadius: "8px",
                        pr: "14px",
                      }}
                      onChange={(event: any) => {
                        const formatedAreaCode = event.target.value.code;

                        setSelectedArea(event.target.value);
                        setAreaCode(event.target.value.code);
                        let formattedArea: string = "";

                        switch (formatedAreaCode) {
                          case "0001":
                            formattedArea = "Johannesburg, South Africa";
                            break;
                          case "0002":
                            formattedArea = "Bethal, South Africa";
                            break;
                          case "0003":
                            formattedArea = "Emalahleni, South Africa";
                            break;
                          case "0004":
                            formattedArea = "Middelburg, South Africa";
                            break;
                          case "0005":
                            formattedArea = "Potchesfstroom, South Africa";
                            break;
                          case "0006":
                            formattedArea = "Randburg, South Africa";
                            break;
                          case "0007":
                            formattedArea = "Paulshof, South Africa";
                            break;
                          case "0008":
                            formattedArea = "Secunda, South Africa";
                            break;
                          case "0009":
                            formattedArea = "Bryanston, South Africa";
                            break;
                          case "0010":
                            formattedArea = "Honeydew, South Africa";
                            break;
                          case "0011":
                            formattedArea = "Sunninghill, South Africa";
                            break;
                        }

                        if (event) {
                          // @ts-ignore
                          setSearchTerm(
                            (event.target as HTMLInputElement).value
                          );
                          // @ts-ignore
                          initService(formattedArea).then((response: any) => {
                            if (response) {
                              if (response.length > 0) {
                                assignLocation(response[0]);
                              }
                            }
                          });
                        }
                      }}
                      placeholder={customLocation.header}
                    >
                      <MenuItem selected disabled>
                        Select Location
                      </MenuItem>

                      {area.map((locationItem: any, index: number) => {
                        return (
                          <MenuItem
                            // @ts-ignore
                            key={index}
                            value={locationItem}
                            onChange={() => {}}
                          >
                            {locationItem.area}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </FormControl>
              </Box>

              {showSearch ? (
                <div className="results-box">
                  <List color="light">
                    <Typography variant="h6" className="font-bold">
                      Suggestions
                    </Typography>
                    <hr color="light" />
                    {locationsList.map((locationItem: any, index: number) => {
                      return (
                        <ListItem
                          key={index}
                          onClick={() => assignLocation(locationItem)}
                        >
                          <ListItemText primary={locationItem.description} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              ) : null}
              <IssueHelpButton />

              <Paper
                sx={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  paddingY: "10px",
                  zIndex: "999",
                  width: { xs: "100%", lg: "50%" },
                  borderRadius: "0",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
                elevation={0}
              >
                <BottomNavigation
                  sx={{
                    width: { xs: "100%", lg: "70%" },

                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    py={3}
                    sx={{
                      display: { xs: "flex" },
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <Button
                        onClick={() => history.push("/onboarding/3")}
                        sx={{
                          padding: "0",
                          margin: "0",
                          display: "flex",
                          borderRadius: "0",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={NextIcon}
                            alt="/"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              transform: "rotate(180deg)",
                            }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "700",
                            color: "#101828",
                          }}
                        >
                          Back
                        </Typography>
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ProgressBar value={100} />
                    </Box>
                    <Box>
                      <Button
                        disabled={!locationSelected}
                        onClick={() => saveLocation()}
                        sx={{
                          padding: "0",
                          margin: "0",
                          display: "flex",
                          borderRadius: "0",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "700",
                            color: !locationSelected ? "grey" : "#101828",
                          }}
                        >
                          Next
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={NextIcon}
                            alt="/"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </BottomNavigation>
              </Paper>
            </form>
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
};
